<template>
  <div class="merchants">
    <PageHeader dilog :border="false" title="企业首营流程" center>
      <Steps :step="active" />
    </PageHeader>
    <div>
      <!-- 新增首营企业 start -->
      <div v-if="active === 0" class="p-lr-20">
        <div class="searchView flex a-center m-tb-10 j-between">
          <div class="flex">
            <div
              v-if="$minCommon.checkMeau('qysyexport', userBottons)"
              class="ModelBttton-white-30 m-right-10"
              @click="downXls"
            >
              <i class="el-icon-upload2 f15 p-right-5" /> 导出
            </div>
            <div
              v-if="$minCommon.checkMeau('qysyfiled', userBottons)"
              class="ModelBttton-white-30 m-right-10"
              @click="() => $refs.FieldManagement.openSetFieldManagement()"
            >
              <i class="el-icon-setting f15 p-right-5" /> 字段管理
            </div>
            <div
              v-if="$minCommon.checkMeau('qysydelete', userBottons)"
              class="ModelBttton-white-30 m-right-10"
              @click="del(1, false)"
            >
              <i class="el-icon-delete f15 p-right-5" /> 删除选中
            </div>
          </div>
          <div v-if="$minCommon.checkMeau('qysyview', userBottons)" class="flex" style="flex: 1">
            <el-input
              v-model="searchWords.KeyWords"
              placeholder="可通过企业名称、公司简称、社会统一信用代码、联系人名称、申请部门、申请人搜索"
              class="rule-input-edit SearchBox_30"
            >
              <template slot="prepend">
                <i class="el-icon-s-operation cursor" @click="Adsearch" />
                <span class="cursor textcolor333" @click="Adsearch"> 高级搜索</span>
              </template>
              <div
                slot="append"
                class="cursor select_none"
                @click="searchGetTableDataAsync()"
              >
                搜 索
              </div>
            </el-input>
            <div v-if="$minCommon.checkMeau('qysyadd', userBottons)" class="out p-left-10 flex a-center">
              <el-button
                round
                type="primary"
                class="ModelBttton-blue-30"
                @click="start"
              >发起新企业首营</el-button>
            </div>
          </div>
        </div>
        <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,searchWords,adSearchForm,'ApplyDate','ApplyDateBegin','ApplyDateEnd')" />

        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          class="TableModel"
          :header-cell-style="headerCStyle"
          :data="tableData"
          tooltip-effect="dark"
          show-header
          height="calc(100vh - 325px)"
          row-key="id"
          border
          fit
          @select-all="selectAll"
          @select="select"
          @selection-change="handleSelectionChange"
          @filter-change="testChange"
        >
          <el-table-column type="selection" fixed="left" />
          <el-table-column
            prop="Status"
            label-position="mid"
            align="center"
            label="状态"
            width="100px"
            column-key="StatusStirng"
            :filter-method="filterHandler"
            :filters="[
              { text: '审核中', value: 1 },
              { text: '已审核', value: 2 },
              { text: '编辑中', value: 0 },
              { text: '已驳回', value: 3 },
            ]"
          >
            <template slot-scope="scope">
              <el-link
                v-if="$minCommon.checkMeau('qysyapprovebox', userBottons)"
                size="mini"
                :type="getInfo(scope.row.Status).type"
                @click="viewShenP(scope.row)"
              >{{ scope.row.StatusString }}</el-link>
              <span v-else>{{ scope.row.StatusString }}</span>
            </template>
          </el-table-column>

          <template v-for="(item,index) in tableListJson.merchants">
            <el-table-column
              v-if="item.isChecked && item.prop !== 'TaxRate' && item.prop !== 'CreditCode'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              :width="item.prop === 'CompanyNo' ? '120px':'auto'"
              :show-overflow-tooltip="item.prop === 'CompanyNo' ? false: true"
            >
              <template v-if="item.isChecked" slot-scope="scope">
                {{ scope.row[item.prop] }}
              </template>
            </el-table-column>
            <!-- 社会统一代码 CreditCode   show-overflow-tooltip -->
            <el-table-column
              v-if="item.isChecked && item.prop === 'CreditCode'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              width="170px"
            >
              <template v-if="item.isChecked && item.prop === 'CreditCode'" slot-scope="scope">
                {{ scope.row.CreditCode }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.isChecked && item.prop === 'TaxRate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 税率 -->
              <template v-if="item.isChecked && item.prop === 'TaxRate'" slot-scope="scope">
                {{ scope.row.TaxRate=== -1 ? '' : scope.row.TaxRate*100 }}
              </template>
            </el-table-column>
          </template>
          <el-table-column
            prop="handle"
            label-position="mid"
            align="center"
            label="操作"
            fixed="right"
            width="50"
          >
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                :width="50"
                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <div v-if="$minCommon.checkMeau('qysyview', userBottons)" class="OperationButton" @click="view_m(scope.row)">
                    <!-- <i class="el-icon-view p-right-10" />
                    <span>查看</span>-->
                    <router-link :to="'/merchants/detail/' + scope.row.IDX + '/0'">
                      <el-link :underline="false"><i class="el-icon-view p-right-5" />查看</el-link>
                    </router-link>
                  </div>
                  <div v-if="(scope.row.Status === 0 || scope.row.Status === 3) && $minCommon.checkMeau('qysyedit', userBottons)" class="OperationButton" @click="edit(scope.row)">
                    <el-link :underline="false"><i class="el-icon-edit p-right-5" />编辑</el-link>
                  </div>
                  <div v-if="scope.row.Status === 0 && $minCommon.checkMeau('qysydelete', userBottons)" class="OperationButton" @click="del(scope.row, true)">
                    <el-link :underline="false"><i class="el-icon-delete p-right-5" />删除</el-link>
                  </div>
                  <div v-if="$minCommon.checkMeau('qysydownload', userBottons)" class="OperationButton" @click="downItem(scope.row)">
                    <el-link :underline="false"><i class="el-icon-download p-right-5" />下载</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :ref="paginationName"
            :total="tableTotalSize"
            :pager-count="pagerCount"
            :page-size="tablePageSize"
            :current-page.sync="currentPage"
            :background="tableBackground"
            layout="prev, pager, next, jumper"
            @current-change="handleCurrentChange($event)"
          />
        </div>
      </div>
      <!-- 新增首营企业 end -->
      <!-- 完善企业信息 start -->
      <!-- <div v-if="active === 1" class="Tailoring">
        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 申请信息</div>
        <el-form
          ref="ruleForm"
          label-position="right"
          :inline="true"
          :rules="rules"
          :model="form"
          size="mini"
          class="demo-form-inline InfoBoxTwo4"
        >
          <el-form-item label="申请日期" prop="ApplyDate">
            <template>
              <div class="m-right-20">{{ form.ApplyDate }}</div>
            </template>
          </el-form-item>
          <el-form-item label="申请部门" prop="ApplyDepartmentID">
            <el-select
              v-model="form.ApplyDepartmentID"
              placeholder="申请部门"
              @change="isBumen"
            >
              <el-option
                v-for="item in PermissionGroup"
                :key="item.IDX"
                :label="item.GroupName"
                :value="item.IDX"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="申请人">
            <el-select
              v-model.number="form.ApplyUserID"
              v-el-select-loadmore="loadmore"
              placeholder="申请人"
            >
              <el-option
                v-for="i in ApplyUserGroup"
                :key="i.UserID"
                :label="i.NickName"
                :value="i.UserID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="填表人">
            <el-select
              v-model.number="userInfo.NickName"
              disabled
              placeholder="填表人"
            >
              <el-option label="小芳" :value="1" />
              <el-option label="小王" :value="2" />
            </el-select>
          </el-form-item>
          <div class="flex a-center">
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="客户" />
                <el-checkbox label="供应商" />
              </el-checkbox-group>
              <span style="color: #f56c6c;margin-right:10px"> *</span>
              <el-popover placement="right-end" width="200" trigger="hover">
                <span> 如果即是客户又是供应商的角色，请勾选两项 </span>
                <i slot="reference" class="el-icon-info" style="color: #ccc" />
              </el-popover>
              <span v-if="checkListisSHOW" class="f12 p-left-20" style="color:#f56c6c;font-weight:400">  如果即是客户又是供应商的角色，请勾选两项 </span>
            </div>
          </div>
          <div class="flex j-between a-center">
            <el-form-item label="企业名称" prop="CompanyName">
              <el-input
                v-model="form.CompanyName"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="社会统一信用代码" prop="CreditCode">
              <el-input
                v-model="form.CreditCode"
                maxlength="18"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="企业简称" prop="date">
              <el-input
                v-model="form.CompanyAbbreviation"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
          </div>
          <div class="flex j-between a-center">
            <el-form-item label="企业编号" prop="date">
              <el-input
                v-model="form.CompanyNo"
                size="mini"
                disabled
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="联系人" prop="date">
              <el-input
                v-model="form.ContactName"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="联系电话" prop="date">
              <el-input
                v-model="form.ContactTel"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
          </div>
          <div class="flex j-between a-center">
            <el-form-item label="传真" prop="date">
              <el-input
                v-model="form.Fax"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="邮箱" prop="date">
              <el-input
                v-model="form.Email"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="法人姓名" prop="date">
              <el-input
                v-model="form.CorporationName"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
          </div>
          <div class="flex j-between a-center">
            <el-form-item label="法人电话" prop="date">
              <el-input
                v-model="form.CorporationTel"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="被授权人" prop="date">
              <el-input
                v-model="form.AuthorizedPerson"
                size="mini"
                placeholder="请输入内容"
              />
            </el-form-item>
          </div>
          <el-form-item label="详细地址" prop="Province" size="mini">
            <div class="flex">
              <el-cascader
                v-model="selectedOptions"
                size="mini"
                :options="options1"
                style="width: 290px"
                @change="handleChange"
              />
              <el-input
                v-model="form.Street"
                size="mini"
                style="margin-left: 10px"
                placeholder="请输入内容"
              />
            </div>
          </el-form-item>

          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
          <div class="InfoBoxTwo4">
            <el-form-item label="开户银行" size="mini">
              <el-input v-model="form.Bank" size="mini" />
            </el-form-item>
            <el-form-item label="开户支行" size="mini" style="width:66.666%">
              <div class="flex">
                <el-cascader
                  v-model="selectedOptions1"
                  size="mini"
                  :options="options1"
                  style="width:150px"
                  @change="handleChange1"
                />
                <el-input
                  v-model="form.BranchBank"
                  size="mini"
                  placeholder="支行"
                  style="width:calc(100% - 150px); margin-left:5px"
                />
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="付款方式" size="mini">
              <el-select
                v-model="form.PayType"
                placeholder="请选择"
              >
                <el-option label="货到付款" value="货到付款" />
                <el-option label="先款后货" value="先款后货" />
                <el-option label="月结" value="月结" />
                <el-option label="先付定金，货到付余款" value="先付定金，货到付余款" />
                <el-option label="其他" value="其他" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="回款账期（天）"
              size="mini"
              prop="PaymentCollectionDays"
            >
              <el-input v-model="form.PaymentCollectionDays" type="number" />
            </el-form-item>
            <el-form-item label="开票税率" size="mini" prop="TaxRate">
              <el-select
                v-model="form.TaxRate"
                placeholder="请选择"
              >
                <el-option label="17%" :value="0.17" />
                <el-option label="16%" :value="0.16" />
                <el-option label="13%" :value="0.13" />
                <el-option label="11%" :value="0.11" />
                <el-option label="10%" :value="0.10" />
                <el-option label="6%" :value="0.06" />
                <el-option label="3%" :value="0.03" />
                <el-option label="1%" :value="0.01" />
                <el-option label="0%" :value="0" />
                <el-option label="   " :value="-1" disabled />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="备注" size="mini" prop="date">
            <el-input
              v-model="form.Remark"
              size="mini"
              placeholder=""
            />
          </el-form-item>
        </el-form>
        <div>
          <PageHeader title="已上传的文件">
            <el-button size="mini" @click="uoLoad">上传证件</el-button>
            <el-button size="mini" type="danger" plain @click="delImgs(1, false)">批量删除</el-button>
          </PageHeader>
          <div class="main_flex flex j-between min-border-bottom">
            <div class="left flex a-center p-tb-20  min-border-right">
              <div
                v-for="(i, index) in tableData1"
                :key="index"
                style="position: relative"
                class="img_view m-tb-20"
              >
                <img :src="imgsUrl + i.PhotoUrl" @click="showPic">
                <div
                  v-if="!isStaticPic"
                  style="position: absolute; right: 5px; top: 5px; z-index: 10"
                  class="cursor"
                  @click="delImgs(i, true)"
                >
                  x
                </div>
              </div>
            </div>
            <div class="right">
              <div class="p-lr-20  m-tb-20">
                <el-table
                  ref="multipleTable"
                  :border="false"
                  :data="tableData1"
                  style="border:none"
                  height="400px"
                  tooltip-effect="dark"
                  @select-all="selectAll1"
                  @select="select1"
                >
                  <af-table-column type="selection" width="55" />
                  <af-table-column
                    prop="TypeString"
                    label="证件类型"
                    width="180"
                  />
                  <af-table-column
                    prop="CompanyName"
                    label="企业名称"
                    width="180"
                  />
                  <af-table-column prop="CertificateCode" label="证件编号" width="250" />
                  <af-table-column prop="ValidToString" label="证件有效期" />
                  <af-table-column prop="Status" label="状态" />
                  <af-table-column v-if="!isStaticPic" prop="address" label="操作" fixed="right" width="50">
                    <template slot-scope="scope">
                      <el-popover
                        placement="left"
                        trigger="hover"
                        :width="50"
                        popper-class="test_pop_view"
                      >
                        <div>
                          <div
                            class="cursor"
                            @click="editImgs(scope.row)"
                          >
                            <i class="el-icon-edit p-right-10" />
                            <span>编辑</span>
                          </div>
                          <div
                            class="cursor"
                            @click="delImgs(scope.row, true)"
                          >
                            <i class="el-icon-delete p-right-10" />
                            <span>删除</span>
                          </div>
                          <div
                            class="cursor"
                            @click="downLoadImgs(scope.row)"
                          >
                            <i class="el-icon-download p-right-10" />
                            <span>下载</span>
                          </div>
                        </div>
                        <i slot="reference" class="el-icon-more" />
                      </el-popover>
                    </template>
                  </af-table-column>
                  <div slot="empty">
                    <p :style="{ marginTop: '10px' }">表格数据为空</p>
                  </div>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div class="caozuo t-right p-top-20">
          <el-button :loading="btnLoadiung1" @click="save1">保存草稿</el-button>
          <el-button type="primary" :loading="btnLoadiung" @click="LaunchApproval1">{{ view_item.Status === 3 ? "重新发起" : "发起审核" }}</el-button>
        </div>
      </div> -->
      <!-- 完善企业信息 end -->
      <!-- 上传企业证照 start -->
      <!-- <div v-if="active === 2">
        <div
          :class="
            isMore
              ? 'TailoringAuto animation'
              : 'Tailoring animation'
          "
        >
          <el-form
            :inline="true"
            label-position="right"
            :model="formInline"
            size="mini"
            label-width="125px"
            class="demo-form-inline"
          >
            <div>
              <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" />申请信息</div>

              <el-form-item label="申请日期" class="eachitem">
                {{ info_temp.ApplyDateString }}
              </el-form-item>
              <el-form-item label="申请部门" class="eachitem">
                {{ info_temp.ApplyDepartment }}
              </el-form-item>
              <el-form-item label="申请人" class="eachitem">
                {{ info_temp.ApplyUser }}
              </el-form-item>
              <el-form-item label="填表人" class="eachitem">
                {{ info_temp.FillFormUser }}
              </el-form-item>
            </div>
            <div>
              <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息</div>
              <el-form-item label="企业名称" class="eachitem">
                {{ info_temp.CompanyName }}
              </el-form-item>
              <el-form-item label="企业简称" class="eachitem">
                {{ info_temp.CompanyAbbreviation }}
              </el-form-item>
              <el-form-item label="企业编号" class="eachitem">
                {{ info_temp.CompanyNo }}
              </el-form-item>
              <el-form-item label="企业类型" class="eachitem">
                {{ CompanyTypeformatter({ CompanyType: info_temp.CompanyType }) }}
              </el-form-item>
            </div>
            <div>
              <el-form-item label="联系人" class="eachitem">
                {{ info_temp.ContactName }}
              </el-form-item>
              <el-form-item label="联系电话" class="eachitem">
                {{ info_temp.ContactTel }}
              </el-form-item>
              <el-form-item label="传真" class="eachitem">
                {{ info_temp.Fax }}
              </el-form-item>
              <el-form-item label="邮箱" class="eachitem">
                {{ info_temp.Email }}
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业法人" class="eachitem">
                {{ info_temp.CorporationName }}
              </el-form-item>
              <el-form-item label="法人电话" class="eachitem">
                {{ info_temp.CorporationTel }}
              </el-form-item>
              <el-form-item label="社会统一信用代码" class="eachitem">
                {{ info_temp.CreditCode }}
              </el-form-item>
              <el-form-item label="被授权人" class="eachitem">
                {{ info_temp.AuthorizedPerson }}
              </el-form-item>
            </div>
            <el-form-item label="详细地址">
              {{ info_temp.Province }} {{ info_temp.City }} {{ info_temp.Area }} {{ info_temp.Street }}
            </el-form-item>
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
            <div>
              <el-form-item label="开户银行" class="eachitem">
                {{ info_temp.Bank }}
              </el-form-item>
              <el-form-item label="开户支行" class="eachitem">
                {{ info_temp.BranchBankProvince }} {{ info_temp.BranchBankCity }} {{ info_temp.BranchBankArea }} {{ info_temp.BranchBank }}
              </el-form-item>
              <el-form-item label="付款方式" class="eachitem">
                {{ info_temp.PayType }}
              </el-form-item>
              <el-form-item label="回款账期" class="eachitem">
                {{ info_temp.PaymentCollectionDays ? info_temp.PaymentCollectionDays:'' }}天
              </el-form-item>
              <el-form-item label="开票税率" class="eachitem">
                {{ getTaxInfo(info_temp.TaxRate) }}<span v-if="getTaxInfo(info_temp.TaxRate)" />
              </el-form-item>
              <el-form-item label="备注：" class="eachitem">
                {{ info_temp.Remark }}
              </el-form-item>
            </div>
          </el-form>
          <div class="t-right rer">
            <el-link v-if="!isMore" class="more cursor m-right-10" @click="() => (isMore = true)">更多</el-link>
            <el-link v-if="!isMore" class="more cursor" @click="setItem()">修改</el-link>
          </div>
          <div v-if="isMore" class="up flex j-center">
            <i class="el-icon-arrow-up" @click="() => (isMore = false)" />
          </div>
        </div>
        <PageHeader title="资质文件">
          <el-button size="mini" @click="uoLoad">上传证件</el-button>
          <el-button size="mini" type="danger" plain @click="delImgs(1, false)">批量删除</el-button>
          <el-button size="mini" type="primary" :loading="btnLoadiung" @click="LaunchApproval">{{ view_item.Status === 3 ? "重新发起" : "发起审核" }}</el-button>
          <el-button size="mini" type="primary" @click="back">保存并返回</el-button>
        </PageHeader>
        <div class="main_flex flex j-between">
          <div class="left min-border-right flex a-center p-tb-20">
            <div
              v-for="(i, index) in tableData1"
              :key="index"
              style="position: relative"
              class="img_view m-tb-20"
            >
              <img :src="imgsUrl + i.PhotoUrl" @click="showPic">
              <div
                style="position: absolute; right: 5px; top: 5px; z-index: 10"
                class="cursor"
                @click="delImgs(i, true)"
              >
                x
              </div>
            </div>
          </div>
          <div class="right">
            <PageHeader :border="false" title="已上传的文件" />
            <div class="p-lr-20">
              <el-table
                ref="multipleTable"
                :border="false"
                :data="tableData1"
                height="400px"
                tooltip-effect="dark"
                @select-all="selectAll1"
                @select="select1"
              >
                <af-table-column type="selection" width="55" />
                <af-table-column
                  prop="TypeString"
                  label="证件类型"
                  width="180"
                />
                <af-table-column
                  prop="CompanyName"
                  label="企业名称"
                  width="180"
                />
                <af-table-column prop="CertificateCode" label="证件编号" width="250" />
                <af-table-column prop="ValidToString" label="证件有效期" />
                <af-table-column prop="Status" label="状态" />
                <af-table-column prop="address" label="操作" fixed="right" width="50">
                  <template slot-scope="scope">
                    <el-popover
                      placement="left"
                      trigger="hover"
                      :width="50"
                      popper-class="test_pop_view"
                    >
                      <div>
                        <div
                          class="cursor"
                          @click="editImgs(scope.row)"
                        >
                          <i class="el-icon-edit p-right-10" />
                          <span>编辑</span>
                        </div>
                        <div
                          class="cursor"
                          @click="delImgs(scope.row, true)"
                        >
                          <i class="el-icon-delete p-right-10" />
                          <span>删除</span>
                        </div>
                        <div
                          class="cursor"
                          @click="downLoadImgs(scope.row)"
                        >
                          <i class="el-icon-download p-right-10" />
                          <span>下载</span>
                        </div>
                      </div>
                      <i slot="reference" class="el-icon-more" />
                    </el-popover>
                  </template>
                </af-table-column>
                <div slot="empty">
                  <p :style="{ marginTop: '10px' }">表格数据为空</p>
                </div>
              </el-table>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 上传企业证照 end -->
    </div>
    <Adsearch
      v-if="active === 0"
      :show="isShow"
      :form="formSearch"
      :ad-search-form="adSearchForm"
      @cancel="(e) => (isShow = e)"
      @searchAd="searchAd"
    />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />

    <el-dialog
      top="5vh"
      width="90%"
      custom-class="custom-class1"
      class="dialogClass"
      :show-close="false"
      :visible="showElement"
    >
      <PageHeader
        title="企业首营申请单"
      ><i
        class="el-icon-close cursor"
        @click="() => (showElement = false)"
      /></PageHeader>
      <div class="main_business flex j-between">
        <!-- 左侧 -->
        <div class="main_business_left flex column">
          <div class="top_process flex a-center min-border-bottom">
            <div
              v-for="(i, n) in process_list"
              :key="n"
              class="top_process_item flex j-left a-center"
              :class="i.value !== '6' ? 'min-border-right' : ''"
            >
              <div class="point" style="margin:0 0 0 10px">
                <i :class="`${i.icon} f25`" />
              </div>
              <div class="flex j-center a-left column p-left-10 f12">
                <div class="weight">{{ i.name }}</div>
                <div class="hui">{{ RecordInfo.RecordInfo[i.value] }}</div>
              </div>
            </div>
          </div>
          <!-- 驳回原因 -->
          <div v-if="RecordInfo.RecordInfo.Status === 2" class="no_reson p-tb-20 p-lr-30 theme-color">
            驳回原因: {{ RecordInfo.RecordInfo.Reason }}
          </div>
          <div class="mid_process">
            <div
              class="flex column p-lr-20"
              style="background-color: #fff; flex: 1; height: auto"
            >
              <div
                v-for="(test, index) in formJson"
                :key="index"
                class="right1"
              >
                <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> {{ test.Name }}</div>
                <el-form
                  :hide-required-asterisk="false"
                  size="small"
                  :label-width="test.Name === '企业证照' ? '0' : '100px'"
                >
                  <div class="j-between" style="display:flex; flex-wrap:wrap">
                    <template v-for="(item, ia) in test.Value">
                      <el-form-item
                        v-if="item.IsShow && item.Label !== '默认占位' "
                        :key="ia"
                        :required="item.Disabled"
                        :style="{
                          width:
                            test.Name === '企业证照'
                              ? '100%'
                              : item.Colspan === 1
                                ? '32.5%'
                                : item.Colspan === 2
                                  ? '60%'
                                  : '100%',
                          height: 'auto',
                        }"
                        :label="
                          item.IsShow
                            ? item.Label === '默认占位'
                              ? ''
                              : item.Label
                            : ''
                        "
                      >
                        <template v-if="test.Name === '企业证照'">
                          <div
                            class="flex"
                            style="width: 100%; flex-wrap: wrap"
                          >
                            <div
                              v-for="(info, index_) in dataJson.CertList"
                              :key="info.index_"
                              class="flex j-center m-bottom-20"
                            >
                              <el-image
                                :src="`${b2bstaticUrl}${info.PhotoUrl}`"
                                style="width: 200px; height: auto"
                                @click="
                                  showImage(
                                    `${b2bstaticUrl}${info.PhotoUrl}`
                                  )
                                "
                              />
                              <div class="m-lr-20">
                                <div class="flex">
                                  <span style="width: 100px">证件名称:</span>
                                  <el-select
                                    v-model="info.CertificateName"
                                    :disabled="disabled"
                                    style="width: 100%"
                                    size="small"
                                    placeholder="请选择"
                                    @change="isChange($event, index_)"
                                  >
                                    <el-option
                                      v-for="item1 in types"
                                      :key="item1.IDX"
                                      :label="item1.Name"
                                      :value="item1.Name"
                                    />
                                  </el-select>
                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                                <div v-if="info.CertificateCode" class="flex p-tb-5">
                                  <span style="width: 100px">证件编号:</span>
                                  <el-input
                                    v-model="info.CertificateCode"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.CompanyName" class="flex p-tb-5">
                                  <span style="width: 100px">机构名称:</span>
                                  <el-input
                                    v-model="info.CompanyName"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.Address" class="flex p-tb-5">
                                  <span style="width: 100px">库房生产地址:</span>
                                  <el-input
                                    v-model="info.Address"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div class="flex p-tb-5">
                                  <span style="width: 100px">备注:</span>
                                  <el-input
                                    v-model="info.Remark"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.ValidToString" class="flex  p-tb-5">
                                  <span style="width: 100px">有效期至:</span>
                                  <el-input
                                    v-model="info.ValidToString"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <el-input
                          v-if="
                            test.Name !== '企业证照' &&
                              item.Label !== '默认占位'
                              && item.Label !== '详细地址'
                              && item.Label !== '开票税率'
                              && item.Label !== '开户支行'
                          "
                          v-model="dataJson[item.Key]"
                          :disabled="disabled"
                          autocomplete="off"
                        />
                        <!-- `` -->
                        <el-input v-if="item.Label == '开票税率' " :value="`${dataJson[item.Key] === -1 ? '' : dataJson[item.Key]*100 ? dataJson[item.Key]*100 + '%' : (dataJson[item.Key]*100 === 0 ? dataJson[item.Key]*100 + '%' : '') }`" :disabled="true" autocomplete="off" />
                        <!--   {{ info_temp.BranchBankProvince }} {{ info_temp.BranchBankCity }} {{ info_temp.BranchBankArea }} {{ info_temp.BranchBank }} -->
                        <el-input v-if="item.Label == '开户支行' " :value="`${dataJson['BranchBankProvince']}${dataJson['BranchBankCity']}${dataJson['BranchBankArea']}${dataJson['BranchBank']}`" :disabled="true" autocomplete="off" />
                        <el-input v-if="item.Label == '详细地址' " :value="`${dataJson['Province']}${dataJson['City']}${dataJson['Area']}${dataJson[item.Key]}`" :disabled="true" autocomplete="off" />
                      </el-form-item>
                    </template>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="btm_process flex j-end a-center min-border-top p-right-10" style="box-sizing: border-box;">
            <el-button
              size="mini"
              icon="el-icon-download"
              @click="downCampZip"
            >打包下载证件</el-button>
            <el-button
              v-if="RecordInfo.RecordInfo.Status === 2"
              size="mini"
              icon="el-icon-refresh"
              @click="restart"
            >重新发起</el-button>
            <el-button
              v-if="RecordInfo.RecordInfo.Status === 0"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              :loading="isAppLa"
              @click="cuiban"
            >催办</el-button>

            <!-- <el-button v-if="view_item.AllowEdit && disabled && view_item.ApprovaPersonStatus === 0" size="mini" type="success" icon="el-icon-edit" @click="setEdit">修改</el-button> -->
            <!-- <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" size="mini" type="danger" icon="el-icon-s-release" @click="RejectApproval(view_item.ApprovalRecordUserDetailsID)">驳回</el-button> -->
            <!-- <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" size="mini" type="primary" icon="el-icon-s-check" @click="AgreeApprovals(view_item.ApprovalRecordUserDetailsID)">通过审核</el-button> -->
            <!-- <el-button v-if="!disabled" size="mini" type="primary" icon="el-icon-folder-checked" @click="saveForm">保存</el-button>
            <el-button v-if="!disabled" size="mini" type="info" icon="el-icon-bottom-left" @click="goBack">返回</el-button> -->
            <div class="p-right-5" />
          </div>
        </div>
        <!-- 右侧 -->
        <div class="main_business_right min-border-left">
          <div class="top_process min-border-bottom">
            <el-tabs v-model="activeName_business" class="SpeedProgress" @tab-click="tabClick">
              <el-tab-pane label="审批日志" name="first">
                <div>
                  <el-timeline style="padding: 5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordList"
                      :key="index"
                      :timestamp="activity.UpdateTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between p-lr-10">
                          <div
                            class="flex j-between p-bottom-5"
                          >
                            <div style="color: #909399">
                              {{ activity.NodeTypeString }}
                            </div>
                            <!-- el-icon-s-order -->
                            <i
                              :class="
                                activity.NodeType === 0
                                  ? 'el-icon-tickets'
                                  : activity.NodeType === 1
                                    ? 'el-icon-s-check'
                                    : 'el-icon-position'
                              "
                              style="color: #096dcd"
                            />
                          </div>
                          <div class="bg_hui">
                            <div v-if="activity.NodeType === 0">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div
                                    v-for="i in activity.ApprovalUserList"
                                    :key="i.IDX"
                                  >
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}
                                  </div>
                                </div>
                                <i
                                  class="el-icon-s-custom f16"
                                  style="color: #ccc"
                                />
                              </el-tooltip>
                              <span
                                class="p-left-10"
                              >{{
                                activity.ApprovalUserList[0].Name
                              }}
                                发起新的</span>
                            </div>
                            <div v-else class="flex">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div
                                    v-for="i in activity.ApprovalUserList"
                                    :key="i.IDX"
                                  >
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}<br>
                                  </div>
                                </div>
                                <i
                                  class="el-icon-s-custom f16"
                                  style="color: #ccc"
                                />
                              </el-tooltip>
                              <div
                                v-if="activity.ApprovalUserList.length >= 1"
                                class="flex p-left-10 el-1"
                              >
                                {{ activity.ApprovalUserList[0].Name }}
                                <span
                                  v-if="activity.ApprovalUserList.length > 1"
                                  class="el-1"
                                >等人</span>
                                {{
                                  activity.ApprovalUserList[0]
                                    .ApprovaPersonStatusString
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="btn flex j-center a-center">
                    <el-button
                      size="mini"
                      @click="DownApprovalRecordList"
                    >导出审批日志</el-button>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="修改日志" name="second">
                <div>
                  <el-timeline style="padding: 5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordEditList"
                      :key="index"
                      :timestamp="activity.WriteTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between p-lr-20">
                          <div
                            class="flex j-between min-border-bottom p-bottom-5"
                          >
                            <div style="color: #909399">
                              {{ activity.NickName }}
                            </div>
                            <!-- el-icon-s-order -->
                            <i class="el-icon-tickets" style="color: #096dcd" />
                          </div>
                          <div class="p-top-5 weight">
                            {{ activity.EditContent }}
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="btn flex j-center a-center">
                    <el-button size="mini" @click="DownApprovalRecordList">导出修改日志</el-button>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="留言" name="third">
                <div class="chat flex column">
                  <div id="content" class="content">
                    <div id="content_view" class="content_view p-lr-20">
                      <div
                        v-for="(chat, ina) in ChatList.MessageList"
                        :key="ina"
                      >
                        <!-- 左侧 别人 -->
                        <div
                          v-if="ChatList.UserID !== chat.UserID"
                          class="left_chat"
                        >
                          <div class="item_chat p-tb-10">
                            <div
                              class="p-tb-10 f12"
                              style="color: #999; text-align: left"
                            >
                              <i class="el-icon-user-solid" />
                              {{ chat.NickName }} {{ chat.WriteTimeString }}
                            </div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image
                                :src="chat.Message"
                                @click="showImage(chat.Message)"
                              />
                            </div>
                            <div
                              v-if="chat.MessageType"
                              class="item_content_message"
                            >
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                        <!-- 左侧 自己 -->
                        <div
                          v-if="ChatList.UserID === chat.UserID"
                          class="right_chat"
                        >
                          <div class="item_chat p-tb-10">
                            <div
                              class="p-tb-10 f12"
                              style="color: #999; text-align: right"
                            >
                              {{ chat.NickName }}{{ chat.WriteTimeString
                              }}<i class="el-icon-user-solid" />
                            </div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image
                                :src="chat.Message"
                                @click="showImage(chat.Message)"
                              />
                            </div>
                            <div
                              v-if="chat.MessageType"
                              class="item_content_message"
                            >
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="send_view flex column">
                    <div class="input_view">
                      <el-input
                        v-model="input_value"
                        type="textarea"
                        :rows="2"
                        resize="none"
                        placeholder="点击发送消息"
                        maxlength="140"
                      />
                    </div>
                    <div class="btm flex a-center j-between p-lr-10">
                      <div>
                        <i class="el-icon-folder p-right-5" @click="selectFile">
                          <input
                            id="file"
                            ref="filElem"
                            style="
                              filter: alpha(opacity=0);
                              opacity: 0;
                              width: 0;
                              height: 0;
                            "
                            type="file"
                            class="upload-file"
                            @change="getFile"
                          >
                        </i>
                        <el-popover
                          placement="top"
                          trigger="click"
                          :width="50"
                          popper-class="test_pop_view"
                        >
                          <div>
                            <div
                              v-for="(i, n) in UserList"
                              :key="n"
                              class="cursor"
                              @click.stop="select_user(i)"
                            >
                              {{ i.NickName }}
                            </div>
                          </div>
                          <span slot="reference" class="cursor">@</span>
                        </el-popover>
                      </div>
                      <div>
                        <span
                          class="f12 p-right-10"
                          style="color: #ccc"
                        >{{ chat_value_length }} /140</span>
                        <span
                          class="weight f14 cursor"
                          style="color: #096dcd"
                          @click="sendMsg(false)"
                        >发送</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-dialog>
    <FieldManagement ref="FieldManagement" action-name="FirstCampCompanyList" :list="tableListJson.merchants" name="merchants" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import Index from '@/minxin/merchants/index'
export default Index
</script>
<style scoped lang='scss'>
@import "@/style/search.scss";
@import "@/style/business.scss";
@import '@/style/product.scss';
</style>
<style lang='scss'>

</style>
